import { twMerge } from "tailwind-merge";

const HeaderSubtitle: React.FC<
  React.PropsWithChildren & { className?: string }
> = ({ children, className }) => (
  <div className={twMerge("text-xs font-semibold text-text", className)}>
    {children}
  </div>
);

export default HeaderSubtitle;
