import { twMerge } from "tailwind-merge";

interface HeaderProps extends React.PropsWithChildren<{}> {
  sticky?: boolean;
  className?: string;
  widthFull?: boolean;
}

export default function Header({
  children,
  sticky = false,
  className = undefined,
  widthFull = false,
}: HeaderProps) {
  return (
    <header
      className={twMerge(
        "z-100 flex w-full items-center bg-background px-4 py-2 shadow-lg tablet:h-16 tablet:px-5 laptop:px-8 desktop:px-8",
        sticky && "sticky top-0",
        className,
      )}
    >
      <div
        className={`mx-auto flex h-8 w-full ${
          widthFull ? "" : "max-w-screen-max"
        } flex-1 items-center justify-between gap-4 tablet:gap-6 laptop:gap-8 desktop:h-10`}
      >
        {children}
      </div>
    </header>
  );
}
