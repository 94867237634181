"use client";

import React from "react";

import {
  Button as ReactAriaButton,
  ButtonProps as ReactAriaButtonProps,
} from "react-aria-components";

import useButton, { ButtonProps } from "./useButton";
import ButtonContent from "./ButtonContent";

interface ButtonComponentProps
  extends ButtonProps,
    Omit<ReactAriaButtonProps, "children" | "className"> {
  children: React.ReactNode;
  badge?: number;
}

const Button = ({
  children = "",
  type = "button",
  ...props
}: ButtonComponentProps) => {
  const { ...buttonProps } = useButton(props);

  return (
    <ReactAriaButton type={type} {...buttonProps}>
      <ButtonContent {...props}>{children}</ButtonContent>
    </ReactAriaButton>
  );
};

export default Button;
